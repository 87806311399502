$(function() {

  var navMenuEl = document.getElementById('#nav-menu');
  var videoLightboxEl = document.getElementById('#video-lightbox');

  $('.page-overlay').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open')
      bodyScrollLock.enableBodyScroll(navMenuEl);
    } else if ($('body').hasClass('video-lightbox-show')) {
      $('body').removeClass('video-lightbox-show')
      bodyScrollLock.enableBodyScroll(videoLightboxEl);
    }
  });

  $('.nav-toggle').click(function(){
    if ($('body').hasClass('nav-open')) {
      $('body').removeClass('nav-open')
      bodyScrollLock.enableBodyScroll(navMenuEl);
    } else {
      // window.scrollTo(0, 0);
      $('body').addClass('nav-open')
      bodyScrollLock.disableBodyScroll(navMenuEl);
    }
  });

  $('#play-video-lightbox').click(function(){
    if ($('body').hasClass('video-lightbox-show')) {
      $('body').removeClass('video-lightbox-show')
      bodyScrollLock.enableBodyScroll(videoLightboxEl);
    } else {
      // window.scrollTo(0, 0);
      $('body').addClass('video-lightbox-show')
      bodyScrollLock.disableBodyScroll(videoLightboxEl);
    }
  });

  $('.services-slider').flickity({
    cellAlign: 'left',
    freeScroll: false,
    wrapAround: false,
    contain: true,
    prevNextButtons: false
  });

  $('#show-more').click(function(){

    var $this = $(this);
    var page = $this.data('page');

    $.get($this.data('url') + '/p' + page, function(data) {
      $('#work-listing').append(data);
      page = page + 1;
      if ($this.data('max') == page) {
        $this.parents('.show-more-wrapper').addClass('hide');
      } else {
        $this.data('page', page);
      }

    }).fail(function() {
      alert('error');
    });
  });

});

var lazy = {

  devMode: false,

  distanceFromBotom: 100,

  log: function(message) {
    if (this.devMode) {
      console.log(message);
    }
  },

  load: function() {
    this.log('function: lazy.load()');
    var lazyEls = document.getElementsByClassName('lazy'),
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    for (var i=0; i<lazyEls.length; i++) {
      var elTop = lazyEls[i].getBoundingClientRect().top + scrollTop;
      // this.log(elTop + ' >= ' + scrollTop);
      this.log(elTop + ' <= ' + (window.innerHeight + scrollTop - this.distanceFromBotom));
      if (elTop <= (window.innerHeight + scrollTop - this.distanceFromBotom)) {
      // if (elTop >= scrollTop && elTop <= (window.innerHeight + scrollTop - 150)) {
        this.log('load');
        this.addClass(lazyEls[i], 'load');
      }
    }
  },

  hasClass: function(el, className) {
    return el.classList ? el.classList.contains(className) : new RegExp('\\b'+ className+'\\b').test(el.className);
  },

  addClass: function(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!this.hasClass(el, className)) el.className += ' ' + className;
  },

  removeClass: function(el, className) {
    if (el.classList) el.classList.remove(className);
    else el.className = el.className.replace(new RegExp('\\b'+ className+'\\b', 'g'), '');
  }
}

document.addEventListener('DOMContentLoaded', function(){

  window.addEventListener('resize', function(){
    lazy.load();
  });

  window.addEventListener('scroll', function(){
    lazy.load();
  });

  lazy.load();
  window.scroll(0, -1);

});
